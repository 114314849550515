import React from 'react'
import { Link } from 'gatsby'

const cancel = (props) => {
  console.log(props)
  return (
    <div>
      Didn't like that? Try <Link to="/new-arrivals"><span className="text-blue-500 hover:text-blue-700 hover:underline">something different...</span></Link>
    </div>
  )
}

export default cancel
